import * as React from "react";
import { Link } from "gatsby";
import "./404.module.scss";

const NotFoundPage = () => {
  return (
    <main>
      <h1>Sorry, there's nothing here.</h1>
      <p>
        Well, there's <i>something</i> here, but it's probably not what you were
        looking for.
      </p>
      <Link to="/">Go back to my home page.</Link>
    </main>
  );
};

export default NotFoundPage;
